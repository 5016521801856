import dayjs from 'dayjs'
import schttp from 'public/src/services/schttp'
import { MESSAGE_DATE, COUPON_TIME_FORMAT_NO_HOURS_BY_ABT }  from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import { timeTransformer, htmlDecode, template } from '@shein/common-function'
import { souceFormat as couponSouceFormat } from 'public/src/pages/components/coupon/mall/utils.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
export * from './enum'
// 百分比格式化
export const percentFormat = (num) => {
  const newNum = (num * 100) / 100
  return newNum
}
// 日期
export const handlerDate = (date, couponTimeChangeAbt) => {
  if (!date) return date
  
  date = (typeof date == 'string') ? date.replaceAll('-', '/') : date
  date = Number(date) ? Number(date) * 1000 : new Date(date + ' GMT+0800').getTime()
  const timer = dayjs(date)

  let sDateMap = MESSAGE_DATE
  if(couponTimeChangeAbt) {
    sDateMap = COUPON_TIME_FORMAT_NO_HOURS_BY_ABT
  }

  const defaultTimer = timer.format('DD/MM/YYYY')
  let newTimer = timeTransformer({ time: date, sDateMap })
  
  return newTimer ? `${ newTimer }` : defaultTimer
}
// 是否展示倒计时
export const isShowCountdownFlag = (couponInfo) => {
  const { selfStatus, timeStatus, end_date } = couponInfo
  // 即将开始
  if (selfStatus === '0' && timeStatus == '1') return false
  let [
    timeStep,
    newDate,
    endDate
  ] = [
    72 * 60 * 60 * 1000, //倒计时72小时
    new Date().getTime(), // 当前时间
    end_date * 1000 // 有效期结束时间
  ]
  // 不在倒计时范围内
  if (newDate + timeStep <= endDate) return false
  return true
}

// 获取价格符号
export const getPriceSymbol = (price = '') => {
  const startSymbol = price?.match(/(^\D+)/)?.[0] || ''
  const endSymbol = price?.match(/(\D+)$/)?.[0] || ''
  return {
    startSymbol,
    endSymbol,
  }
}

// 模版 折扣文案格式 start
export const handelDiscountFormat = (couponInfo, ruleItem, language) => {
  if (couponInfo.coupon_type_id === '1') {
    return [
      `-${ruleItem.value.amountWithSymbol}`,
      ''
    ]
  } else {
    const text = language.SHEIN_KEY_PWA_23808 || '{0} off'
    return [
      percentFormat(ruleItem.value.amount),
      `${text.replace('{0}', '').replaceAll(' ', '')}`
    ]
  }
}


// 可用券的折扣文案
export const handelAccountDiscountFormat = (couponInfo, ruleItem, language) => {
  const { amountWithSymbol } = ruleItem.value
  if (couponInfo.coupon_type_id === '1') {
    return `-${amountWithSymbol}`
  } else {
    const text =  language.SHEIN_KEY_PWA_28531 || '{0}% OFF'
    const amountIndex = text.indexOf('{0}%')
    const offText = text.replace('{0}%', '').replaceAll(' ', '')
    const amount = `${percentFormat(ruleItem.value.amount)}%`
    return {
      amountIndex,
      offText,
      amount
    }
  }
}

// 预搜曝光code
export const searchCouponExposeCode = 'searchCouponExpiredExposeCode'

// 本地曝光次数缓存key
export const pdsearchCouponTipLocalKey = 'pdsearchCouponTipShowTimes'
export const preSearchCouponTipLocalKey = 'preSearchCouponTipShowTimes'

/**
 * 存储搜索结果页 提示的展示时间  做最大次数展示判断
 * @param {String 使用的key} 
 */
export const setSearchCouponNotiTimes = (localKey) => {
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''
  const now = Date.now()

  const ONEDAY = 1000 * 60 * 60 * 24

  let lastShowData = localStorage.getItem(localKey + member_id)
  if (lastShowData) {
    lastShowData = JSON.parse(lastShowData)
  } else {
    lastShowData = []
  }
  
  lastShowData.push(now)

  lastShowData = lastShowData.filter(v => {
    return v > now - ONEDAY
  })

  window.localStorage.setItem(localKey + member_id, JSON.stringify(lastShowData))
}

/**
 * 判断搜索结果页 提示的24内的展示次数展示  做最大次数展示判断
 * @param {String 使用的key}
 * @param {Number 允许的最大展示次数} 
 */
export const checkSearchCouponNotiOnOneDayNumber = (localKey, maxNumber) => {
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''

  let lastShowData = localStorage.getItem(localKey + member_id)
  if (!lastShowData) {
    return true
  }

  if (lastShowData) {
    lastShowData = JSON.parse(lastShowData)
  } else {
    lastShowData = []
  }

  const now = Date.now()

  const ONEDAY = 1000 * 60 * 60 * 24
  
  lastShowData = lastShowData.filter(v => {
    return now - v < ONEDAY
  })

  return lastShowData.length < maxNumber
}

// 券文案
export const handelCouponSubTitle = (couponInfo, ruleItem, language) => {
  const min = ruleItem.min
  const max = ruleItem.max
  
  if ( max && max.amountWithSymbol ) return template(
    min.amountWithSymbol,
    max.amountWithSymbol,
    htmlDecode({ text: language.SHEIN_KEY_PWA_18572 })
  )

  if (couponInfo.max_three_coupon_rule.length > 1) {
    return language.SHEIN_KEY_PWA_25041?.replace('{0}', min.amountWithSymbol)
  } else {
    return language.SHEIN_KEY_PWA_15817?.replace('{0}', min.amountWithSymbol)
  }
}

/**
 * 存储列表页优惠券 显示时间，做显示次数判断
 * @param {String 页面名称} pageName 
 * @param {Array 券列表数据} couponList 
 */
export const setCouponExposeTimes = (pageName, couponList, cateId) => {
  const selectPage = ['page_real_class', 'page_select_class']
  // 是否搜索页
  const isSearchPage = pageName === 'page_search'

  // 是否选品页
  const isSelectPage = selectPage.includes(pageName)

  const now = Date.now()

  const ONEDAY = 1000 * 60 * 60 * 24

  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''

  let localKey = ''
  let lastShowData = null
  if (isSearchPage) {
    localKey = 'searchCouponLastShowTime'

    lastShowData = localStorage.getItem(localKey)

    if (lastShowData) {
      lastShowData = JSON.parse(lastShowData)
    } else {
      lastShowData = {}
    }
    couponList.forEach(({ couponCode }) => {
      if (Array.isArray(lastShowData[couponCode])) {
        const newTimes = lastShowData[couponCode].filter(v => now - v < ONEDAY)
        newTimes.push(now)
        lastShowData[couponCode] = newTimes
      } else {
        lastShowData[couponCode] = [now]
      }
    })

    if (cateId) {
      const cateLocalKey = 'searchCouponCateIds_'
      let cateLocalData = JSON.parse(window.localStorage.getItem(`${cateLocalKey}${member_id}`))
      if (cateLocalData) {
        cateLocalData[cateId] = now
      } else {
        cateLocalData = {
          [cateId]: now
        }
      }

      window.localStorage.setItem(cateLocalKey, JSON.stringify(cateLocalData))
    }


  } else if (isSelectPage) {
    localKey = `selectListDisplayedCoupon_${member_id}`
    lastShowData = localStorage.getItem(localKey)

    if (lastShowData) {
      lastShowData = JSON.parse(lastShowData)
    } else {
      lastShowData = {}
    }

    couponList.forEach(({ couponCode }) => {
      couponCode && (lastShowData[couponCode] = [now])
    })
  }

  window.localStorage.setItem(localKey, JSON.stringify(lastShowData))
}


// 绑定品类券
export const bindSearchCoupon = async (couponResult) => {
  const couponCodes = couponResult?.info?.list.map(v => v.coupon)
  if (couponCodes) {
    const res = await schttp({
      method: 'POST',
      url: '/api/coupon/bindCouponsByCoupon/update',
      data: { coupon: couponCodes, scene: 55 }
    })
    const successList = res?.info?.successList ?? []
    const failureList = res?.info?.failureList ?? []
    if (res?.code == '0' && successList.length) {
      return {
        code: '0',
        successList,
        failureList
      }
    } else {
      return {
        code: res.code,
        successList: [],
        failureList
      }
    }

  }

  return {
    code: '0',
    successList: [],
    failureList: []
  }
}

// 绑定券包
export const bindCouponPackage = async (couponResult = {}) => {
  const couponId = couponResult?.info?.couponPackage?.id

  if (couponId) {
    const res = await schttp({
      method: 'POST',
      url: '/api/coupon/bindCouponPackage/update?scene=5',
      data: { couponId: couponId }
    })
    
    const successList = res?.info?.bindResult?.successList ?? []
    if (res?.code == '0' && successList.length) {
      return {
        code: res.code,
        successList
      }
    } else {
      return {
        code: res.code,
        successList: []
      }
    }
  }
  
  return {
    code: couponResult?.code ?? '0',
    successList: []
  }
}

/**
 * 获取券包/品类券 默认为2者都含有
 *
 * @param {Object} cateCoupon - store中的券信息
 * @param {Object} options - 一些配置信息
 * @param {boolean} options.needCateCoupon - 是否含有品类券
 * @param {boolean} options.needCouponPackage - 是否含有券包券
 * @return {Promise} 返回为Promise包数组
 */
export const getAllCoupon = async (originCouponReturn, options = {
  needCateCoupon: true,
  needCouponPackage: true
}) => {
  const { needCateCoupon = true, needCouponPackage = true } = options || {}
  const bindCouponHandelList = [null, null, null]
  // 品类券
  if (needCateCoupon && originCouponReturn.cateCoupon) {
    bindCouponHandelList[0] = bindSearchCoupon(originCouponReturn.cateCoupon)
  }
  // 券包券
  if ( needCouponPackage && originCouponReturn.packageCoupon ) {
    // 登录后需要重新获取一下券包券
    const couponPackageRes = await schttp({ url: '/product/getCouponPackage?' + Math.random() })

    // 登录后可能命中的券不一样，需要重新获取一遍，当获取不到，可能已领过或其他情况，在绑定券包接口中处理
    if (couponPackageRes?.info?.couponPackage.id) {      
      couponPackageRes.info.couponPackage.coupon = couponPackageRes.info.couponPackage?.coupon.map(v => couponSouceFormat(v)) ?? []
      bindCouponHandelList[1] = bindCouponPackage(couponPackageRes)
      bindCouponHandelList[2] = couponPackageRes
    } else {
      bindCouponHandelList[1] = bindCouponPackage(originCouponReturn.packageCoupon)
      bindCouponHandelList[2] = originCouponReturn.packageCoupon
    }
  }
  return Promise.all(bindCouponHandelList)
}
