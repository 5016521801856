import Vue from 'vue'
import { getQueryString } from '@shein/common-function'
import { componentStateFactory, pageStateFactory, resultsFactory } from './state'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { transformSaleTags } from 'public/src/pages/common/promotion/logic/transformSaleTags'
import { preFormatFilterAttrs, getAttrExporConfigByAbt, getStickyConfig, getSearchDiff,
  getFinalFlowRankingList,
  getFilterBarQueryParams,
} from './utils'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { getExposedGoods } from 'public/src/pages/common/refresh_list_strategy/helpers/index'
// import { removeExposedProducts } from 'public/src/pages/common/refresh_list_strategy/helpers/index.js'

const mutations = {
  resetLocals(state, payload) {
    if (payload?.locals) return state.Locals = payload.locals
    if (typeof window === 'undefined') return

    const { WEB_CLIENT, IS_RW, SiteUID, host, lang, langPath, appLanguage, GB_cssRight, is_include_critical_css, LAZY_IMG, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, IMG_LINK, dateLangMap, RESOURCE_SDK, PUBLIC_CDN } = gbCommonInfo
    const currency = UserInfoManager.get({ key: 'currency' })
    state.Locals = { WEB_CLIENT, IS_RW, SiteUID, host, lang, langPath, appLanguage, GB_cssRight, is_include_critical_css, LAZY_IMG, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, IMG_LINK, dateLangMap, currency, RESOURCE_SDK, PUBLIC_CDN }
  },
  resetState(state, { payload, PageStateArgs, ComponentStateArgs }) {
    if (payload.language) state.Language = payload.language
    if (!state.GoodsItemInfoServ) state.GoodsItemInfoServ = new GoodsItemInfo({ itemLanguage: state.Language, locals: state.Locals })

    state.PageState = pageStateFactory(PageStateArgs)
    state.ComponentState = componentStateFactory(ComponentStateArgs)
    state.Results = resultsFactory()
  },
  dealingFlowDataV2(state, {
    flowDataV2,
    sum,
    cat_info,
    bffFlowData,
  }) {
    const catInfo = state.Results.cat_info || {}
    const cccConfig = state.Results.cccConfig || {}
    const listAbtResult = state.Results.listAbtResult || {}

    const { flowRankingList: flowRankCcc } = cccConfig
    const { rankingList = [], flowDiscountChannel, flowStoreFollowing, flowSurveyInfo } = flowDataV2 || {}
    const { ListComponent } = listAbtResult

    state.ComponentState.ProductList.flowDataV2.isAdultRankSwitchBff = bffFlowData?.isAdultRankSwitchBff
    if (!bffFlowData?.isAdultRankSwitchBff) {
      // 融合之后走这里：public/src/pages/product_app/store/modules/product_list_v2/action_flow.js
      state.ComponentState.ProductList.flowDataV2.flowRank = getFinalFlowRankingList({ sum, flowRankCcc, rankingList, ListComponent, catInfo })
    }

    if (cat_info.requestType !== 'nextpage') {
      state.ComponentState.ProductList.flowDataV2.flowDiscountChannel = flowDiscountChannel || {}
      state.ComponentState.ProductList.flowDataV2.flowStoreFollowing = flowStoreFollowing || {}
      state.ComponentState.ProductList.flowDataV2.flowSurveyInfo = flowSurveyInfo || {}

      if (bffFlowData?.flowListBanner) {
        state.ComponentState.ProductList.flowDataV2.flowListBanner = bffFlowData.flowListBanner
      }
    }
  },

  // 初始化、筛选接口init初始化
  dealingInitOrFilterInfoNew(state, { atomicParams, listAbtResult, cccSeo, cccOperation, cccxConfig, cccxFilterConfig, shouldUseCccxFilterChain, cat_info, searchBannerCard, currentCat, parentCats, mallInfo, filterAbtParam, cccConfig, sheinClubInfo, promotionInfoFromServer, picksMenuList, bgBanner, policyBanner, discountPageInfo, apolloConfig } ) {
    if (cat_info.requestType === 'firstload' || cat_info.requestType === 'switchTab') {
      listAbtResult && Vue.set(state.Results, 'listAbtResult', listAbtResult)
      Vue.set(state.Results, 'currentCat', currentCat)
      Vue.set(state.Results, 'parentCats', parentCats)
      Vue.set(state.Results, 'cccSeo', cccSeo)
      Vue.set(state.Results, 'cccOperation', cccOperation)
      Vue.set(state.Results, 'cccxConfig', cccxConfig)
      Vue.set(state.Results, 'cccxFilterConfig', cccxFilterConfig)
      Vue.set(state.Results, 'shouldUseCccxFilterChain', shouldUseCccxFilterChain)
      Vue.set(state.Results, 'searchBannerCard', searchBannerCard)
      Vue.set(state.Results, 'mallInfo', mallInfo)
      Vue.set(state.Results, 'filterAbtParam', filterAbtParam)
      Vue.set(state.Results, 'cccConfig', cccConfig)
      Vue.set(state.Results, 'sheinClubInfo', sheinClubInfo)
      Vue.set(state.Results, 'atomicParams', atomicParams) // init
      Vue.set(state.Results, 'policyBanner', policyBanner) // init
      Vue.set(state.Results, 'apolloConfig', apolloConfig) // init
      cat_info.requestType === 'firstload' && Vue.set(state.Results, 'bgBanner', bgBanner) // init
      cat_info.requestType === 'firstload' && Vue.set(state.Results, 'picksMenuList', picksMenuList) // init 
    } else if (cat_info.switchBff && cat_info.requestType !== 'nextpage') {
      // bff下更新 productTypeSortConfig
      state.Results?.cccConfig && (state.Results.cccConfig.productTypeSortConfig = cccConfig?.productTypeSortConfig || [])
    }

    Vue.set(state.Results, 'cat_info', cat_info) // init
    Vue.set(state.Results, 'promotionInfoFromServer', promotionInfoFromServer) // init

    // 首次加载才需要修改 discountPageInfo，相当于 services/discount_channel_list/goods.js shouldRequestAllTab 的判断
    if (['refresh', 'firstload'].includes(cat_info.requestType)) {
      Vue.set(state.Results, 'discountPageInfo', discountPageInfo)
    }
  },

  // 商品接口相关info初始化
  dealingGoodsItemInfoNew(state, { cat_info, searchKeywords, sum, sumForPage, cardConfig }) {
    if (searchKeywords && state.Results.searchKeywords) {
      searchKeywords.double_lang_correct = state.Results.searchKeywords?.double_lang_correct || searchKeywords.double_lang_correct
    }

    if (cat_info.requestType === 'firstload' || cat_info.requestType === 'switchTab') {
      Vue.set(state.Results, 'searchKeywords', searchKeywords)
      Vue.set(state.Results, 'cardConfig', cardConfig)
    }
    Vue.set(state.Results, 'cat_info', cat_info) // 补充cate_info信息。
    Vue.set(state.Results, 'sum', sum)
    Vue.set(state.Results, 'sumForPage', sumForPage)
  },

  /**
   * Start - dealingNewData -- 预处理请求数据并储存至 vuex
   * @param {*} payload response
   */
  dealingPageInfos(state, { atomicParams, listAbtResult, cccSeo, cccOperation, cccxConfig, cccxFilterConfig, shouldUseCccxFilterChain, cat_info, searchBannerCard, currentCat, parentCats, searchKeywords, sum, sumForPage, mallInfo, filterAbtParam, cccConfig, sheinClubInfo, promotionInfoFromServer, picksMenuList, bgBanner, policyBanner, discountPageInfo, apolloConfig, cardConfig, frontFilterGoods }) {
    // 模拟瀑布流
    // if (listAbtResult) {
    //   listAbtResult.ListLoadmore = listAbtResult.ListLoadmore || {}
    //   listAbtResult.ListLoadmore.p = 'type=waterfall'
    // }

    const isFirstLoad = cat_info.requestType === 'firstload' // 首次加载
    const isSwitchTab = cat_info.requestType === 'switchTab' // 切tab(picks特有)
    const isRefresh = cat_info.requestType === 'refresh' // 筛选
    const isNextPage = cat_info.requestType === 'nextpage' // 翻页

    // 搜索双语纠错信息需要继承下去
    if (searchKeywords && state.Results.searchKeywords) {
      searchKeywords.double_lang_correct = state.Results.searchKeywords?.double_lang_correct || searchKeywords.double_lang_correct
    }
    
    // firstload: 首次进入页面，switchTab: picks切换tab
    if (isFirstLoad || isSwitchTab) {
      if (state.Locals.IS_RW && currentCat?.brand_code == 28 && currentCat?.brande_detail?.detail_type == 2) {
        currentCat.select_name_multi = 'ROMWE X'
      }
      listAbtResult && Vue.set(state.Results, 'listAbtResult', listAbtResult) // mock_flow_abt
      Vue.set(state.Results, 'currentCat', currentCat)
      Vue.set(state.Results, 'parentCats', parentCats)
      Vue.set(state.Results, 'searchKeywords', searchKeywords)
      Vue.set(state.Results, 'cccSeo', cccSeo)
      Vue.set(state.Results, 'cccOperation', cccOperation)
      Vue.set(state.Results, 'cccxConfig', cccxConfig)
      Vue.set(state.Results, 'cccxFilterConfig', cccxFilterConfig)
      Vue.set(state.Results, 'shouldUseCccxFilterChain', shouldUseCccxFilterChain)
      Vue.set(state.Results, 'searchBannerCard', searchBannerCard)
      Vue.set(state.Results, 'mallInfo', mallInfo)
      Vue.set(state.Results, 'filterAbtParam', filterAbtParam)
      Vue.set(state.Results, 'cccConfig', cccConfig)
      Vue.set(state.Results, 'sheinClubInfo', sheinClubInfo)
      Vue.set(state.Results, 'atomicParams', atomicParams)
      Vue.set(state.Results, 'policyBanner', policyBanner)
      Vue.set(state.Results, 'apolloConfig', apolloConfig)
      Vue.set(state.Results, 'cardConfig', cardConfig)
      cat_info.requestType === 'firstload' && Vue.set(state.Results, 'bgBanner', bgBanner)
      cat_info.requestType === 'firstload' && Vue.set(state.Results, 'picksMenuList', picksMenuList)
    } else if (cat_info.switchBff && cat_info.requestType !== 'nextpage') {
      // bff下更新 productTypeSortConfig
      state.Results?.cccConfig && (state.Results.cccConfig.productTypeSortConfig = cccConfig?.productTypeSortConfig || [])
    }

    Vue.set(state.Results, 'cat_info', cat_info)
    Vue.set(state.Results, 'sum', sum)
    Vue.set(state.Results, 'sumForPage', sumForPage)
    Vue.set(state.Results, 'promotionInfoFromServer', promotionInfoFromServer)

    // 首次加载才需要修改 discountPageInfo，相当于 services/discount_channel_list/goods.js shouldRequestAllTab 的判断
    if (isRefresh || isFirstLoad) {
      Vue.set(state.Results, 'discountPageInfo', discountPageInfo)
    }

    const originFrontFilterGoods = state.Results.frontFilterGoods
    const joinFrontFilterGoods = 
      !isNextPage 
        ? frontFilterGoods
        : originFrontFilterGoods 
          ? frontFilterGoods 
            ? `${originFrontFilterGoods},${frontFilterGoods}` 
            : originFrontFilterGoods
          : frontFilterGoods
    Vue.set(state.Results, 'frontFilterGoods', joinFrontFilterGoods?.split(',').slice(0, 240).join())
  },
  initProductList({ ComponentState, GoodsItemInfoServ }, { catInfo, cardConfig }) {
    if (catInfo.requestType === 'firstload') {
      ComponentState.ProductList.goodsItemInfoServ = GoodsItemInfoServ
      
      const config = Object.assign({}, cardConfig)
      Object.freeze(config)
      Vue.set(ComponentState.ProductList, 'config', config)
    }
  },
  dealingGoods({ PageState, ComponentState, Results, Language }, { goods, cat_info, promotionInfoFromServer, searchOptimize, imgItemPrefromanceIndex }) {
    const nextpage = cat_info.requestType === 'nextpage'
    const originalGoods = Results.goods || []
    const { listSpuPic, searchSpuPic, LocFilterUpdate } = Results.listAbtResult || {}
    const { config } = ComponentState.ProductList
    const { exposeDynamicRecommendGood = [] } = Results // 当前页面中的所有点推商品

    let appendGoods = goods
    
    if (!goods?.length) {
      Vue.set(Results, 'goods', nextpage ? originalGoods : [])
      !searchOptimize && Vue.set(PageState, 'noMoreData', true) // 有搜索结果优化标志的因为会在客户端处理重复数据，导致goods为空，因此此情况下，不noMoreData处理
      Vue.set(PageState, 'searchOptimizeReqFlag', false)
      Vue.set(Results, 'appendGoods', [])
      Vue.nextTick(() => {
        if (typeof window !== 'undefined') {
          appEventCenter.$emit('goodsLoadedOnPageList')
        }
      })
      return
    }

    // spu图去重
    const showListSpuPic = listSpuPic?.p?.ListSpuPic === 'show'
    const showSearchSpuPic = searchSpuPic?.p?.SearchSpuPic === 'show'

    if (showListSpuPic || showSearchSpuPic) {
      appendGoods.forEach(goods => {
        let spuImage = (goods?.spu_image || [])[0]?.medium_image
        if (PageState.spuImgMap[spuImage]) {
          goods.spu_image[0].medium_image = 'filteredSpuImg'
        }
        if (spuImage) PageState.spuImgMap[spuImage] = 1
      })
    }

    // 处理过滤，分页返回的接口数据过滤掉当前页面中已有的点推商品
    // let nowPageGoodsIdArr = originalGoods.map(item => item.goods_id)
    // console.log('当前页面点推商品', exposeDynamicRecommendGood)
    appendGoods = appendGoods.filter(item => !exposeDynamicRecommendGood.includes(`${item.goods_id}`))
    
    // 新的商品预处理服务 - 目前只有促销和分布式筛选
    appendGoods = dealProductsPretreatInfo({
      products: appendGoods,
      itemConfig: config,
      promotionInfoFromServer,
      language: Language,
      searchFilterDependencies: {
        picTopNav: Results.picTopNav,
        cloudTags: Results.cloudTags,
        filterWords: typeof window === 'undefined' ? [] : getQueryString({ key: 'filterWords' })?.split(','),
        NoSellingPoint: LocFilterUpdate?.param?.NoSellingPoint === 'new',
        SameFilter: LocFilterUpdate?.param?.SameFilter === 'yes'
      }
    })

    function checkGoodsLength(goods) {
      if (goods.length === 0) {
        // 在这里一整页数据才加载完成, 并且 dom 也渲染完成, 瀑布流也塞好了商品
        Vue.nextTick(() => {
          if (typeof window !== 'undefined') {
            appEventCenter.$emit('goodsLoadedOnPageList')
          }
        })
        return false
      }
      return true
    }

    // 分块渲染
    function setGoods(goods, chunkSize, isInitialCall) {
      if (!checkGoodsLength(goods)) return
      requestAnimationFrame(() => {
        ComponentState.ProductList.isFirstItem = false
        const goodsChunk = goods.slice(0, chunkSize)
        const productsList = [...(Results.goods || []), ...goodsChunk]
        Vue.set(Results, 'goods', (nextpage || !isInitialCall) ? productsList : goodsChunk)
        setGoods(goods.slice(chunkSize), chunkSize, false)
      })
    }

    function setGoodsForInitialCall(goods) {
      if (!checkGoodsLength(goods)) return
      let chunkSize = 1
      //  筛选全部渲染，避免商品过多出现闪屏【如：筛选返回60商品，筛选前滚动10页，逐个渲染需要渲染60次，切页面逐步下拉，视觉上有闪动】
      cat_info.requestType === 'refresh' && (chunkSize = Math.floor(goods.length / 3) || 1) 
      if (nextpage) {
        setGoods(goods, chunkSize, false)
        return 
      }
      requestAnimationFrame(() => {
        ComponentState.ProductList.isFirstItem = false
        markPoint({ eventName: 'ProductListV2Container', measureFrom: 'PageResolveData' })
        markPoint({ eventName: 'XHRLoadGoods', measureFrom: 'ApiResponseGoods' }) 

        // 策略优先展示图片,在当前index的最后两个商品项先游戏那展示图片，在下一个任务在展示其他标签
        if (imgItemPrefromanceIndex && goods.length >= imgItemPrefromanceIndex) {
          const setGoodsMainItems = goods.slice(0, imgItemPrefromanceIndex)
          const startIndex = imgItemPrefromanceIndex - 2
          for (let i = startIndex; i < imgItemPrefromanceIndex; i++) {
            setGoodsMainItems[i]._isPriorityMainImg = true
          }
          const oneItems = goods.slice(0, 1)
          Vue.set(Results, 'goods', oneItems)
          goods = goods.slice(1)
          requestAnimationFrame(() => {
            Vue.set(Results, 'goods', (Results.goods || []).concat(goods.slice(0, 3)))
            goods = goods.slice(3)
            for (let i = startIndex; i < imgItemPrefromanceIndex; i++) {
              setGoodsMainItems[i]._isPriorityMainImg = false
              Vue.set(Results.goods, i, setGoodsMainItems[i])
            }
            setGoods(goods, chunkSize, false)
          })
          return
        }

        Vue.set(Results, 'goods', goods.slice(0, chunkSize))
        goods = goods.slice(chunkSize)
        if (!checkGoodsLength(goods)) return
        requestAnimationFrame(() => {
          markPoint({ eventName: 'FirstItemPush', measureFrom: 'XHRLoadGoods' })
          const twoPushSize = 3
          Vue.set(Results, 'goods', (Results.goods || []).concat(goods.slice(0, twoPushSize)))
          setGoods(goods.slice(twoPushSize), chunkSize, false)
          requestAnimationFrame(() => {
            markPoint({ eventName: 'TwoChangetemPushEnd', measureFrom: 'FirstItemPush' })
            markPoint({ eventName: 'FirstScreenTask', measureFrom: 'ApiResponseGoods' }) 
          })
        })
      })
    }

    Vue.set(PageState, 'noMoreData', (appendGoods.length + originalGoods.length) >= Results.sum)
    Vue.set(PageState, 'searchOptimizeReqFlag', false)
    Vue.set(Results, 'appendGoods', appendGoods)

    if (!PageState.isSpaPage && cat_info.requestType === 'firstload') {
      ComponentState.ProductList.isFirstItem = true
      // 只有 ssr 渲染 + 首页数据, 才一次性渲染一页商品
      Vue.set(Results, 'goods', appendGoods)
      Vue.nextTick(() => {
        if (typeof window !== 'undefined') {
          appEventCenter.$emit('goodsLoadedOnPageList')
        }
      })
    } else {
      ComponentState.ProductList.isFirstItem = !nextpage
      // 其他场景 (SPA 页内跳转; 筛选), 一律分块渲染, 提高性能

      setGoodsForInitialCall(appendGoods)

      // console.log('最终渲染完 Result.goods 为：', JSON.stringify([...Results.goods.map(item => item.goods_id), appendGoods.map(item => item.goods_id)]))
    }
  },
  deleteUnexposedGoods({ ComponentState, Results }, deleteIndex){    // 未曝光的商品在这里删除，然后拼接最新商品
    ComponentState.productListDeleteFlag = true
    Results.goods.splice(deleteIndex + 1)
  },
  removeUnexposedGoods({ ComponentState, Results }) {
    const originalGoods = Results.goods || []
    // console.log('originalGoods 删除前：', JSON.stringify(originalGoods.map(item => item.goods_id)))

    // 删除前锁定瀑布流
    ComponentState.productListDeleteFlag = true

    // 已经分页加载的商品 处理删除商品逻辑
    // 已经加载的商品可能包含点推回来的商品，点推回来的商品会当做已经曝光的商品处理，避免被误删
    // for (let i = originalGoods.length - 1; i >= 0; i--) {
    //   const isExposedGood = Results.exposedGoods.find(item => item.goods_id == originalGoods[i].goods_id)
    //   if (!isExposedGood) {
    //     originalGoods.splice(i, 1)
    //   }
    // }

    /**
     * 使用最大曝光商品的深度来决定删除的商品
     * 1. 获取瀑布流最底的两个曝光商品
     * 2. 将最底两个曝光商品在 originalGoods 中找到最大 index
     * 3. 倒序删除未曝光商品，直到最大 index 的位置停止
     */
    const exposedGoods = Results.exposedGoods

    const { maxIndex } = getExposedGoods({ exposedProducts: exposedGoods, goods: originalGoods })

    // getExposedGoods

    // const lastExposedGood = exposedGoods[exposedGoods.length - 1]
    // const secondLastExposedGood = exposedGoods[exposedGoods.length - 2] 

    // const lastExposedGoodIndex = originalGoods.findIndex(item => item.goods_id == lastExposedGood.goods_id)
    // const secondLastExposedGoodIndex = originalGoods.findIndex(item => item.goods_id == secondLastExposedGood.goods_id)

    // const removeEndedIndex = secondLastExposedGoodIndex > lastExposedGoodIndex ? secondLastExposedGoodIndex : lastExposedGoodIndex

    for (let i = originalGoods.length - 1; i >= 0; i--) {
      if (i > maxIndex) {
        originalGoods.splice(i, 1)
      }
    }

    // console.log('originalGoods 删除后：', JSON.stringify(originalGoods.map(item => item.goods_id)))
  },
  filterUnexposedGoods({ ComponentState, Results }, { exposedCardList, recommendCardList } ){
    // 过滤未曝光的商品
    ComponentState.productListDeleteFlag = true
    const recommendProductIds = new Set(
      recommendCardList.map(item => item.goods_id).filter(Boolean)
    )
    const { maxIndex } = getExposedGoods({ exposedProducts: exposedCardList, goods: Results.goods })
    
    Vue.set(Results, 'goods', Results.goods.filter((item, i) =>
      !item?.goods_id || i <= maxIndex || recommendProductIds.has(item?.goods_id)
    ))
  },
  updateSearchOptimizeReqFlag({ PageState }, value){ // 搜索优化-请求🔒标记更新状态
    PageState.searchOptimizeReqFlag = value
  },
  dealingOriginFilters({ Results }, { cat_info, dailyDates, filterCates, filterPrices, picTopNav, picTopNavCate }) {
    Vue.set(Results, 'dailyDates', dailyDates ? dailyDates : {})
    Vue.set(Results, 'filterCates', filterCates?.length ? filterCates : [])

    const firstloadPage = cat_info.requestType === 'firstload' || cat_info.requestType === 'switchTab'
    
    // 价格筛选
    const isPriceFilter = ['min_price', 'max_price'].some(key => cat_info[key] || cat_info[key] === 0)
    !isPriceFilter && Vue.set(Results, 'filterPrices', filterPrices ? filterPrices : {})
    // 图文导航set
    firstloadPage && Vue.set(Results, 'picTopNav', picTopNav ? picTopNav : [])
    // 图文子类目数据set
    Vue.set(Results, 'picTopNavCate', picTopNavCate || [])
  },
  dealingAttributes({ Locals: { SiteUID, appLanguage }, PageState, Results }, { listAbtResult: { ListAttrSequence } = {}, filterAttrs, hotFilterAttrs, isPlusSize }) {
    filterAttrs = filterAttrs?.filter(_ => _.attr_name)
    if (!filterAttrs?.length) return Vue.set(Results, 'filterAttrs', [])
    preFormatFilterAttrs({
      SiteUID,
      appLanguage,
      filterAttrs,
      hotFilterAttrs,
      isPlusSize,
      ListAttrSequence: ListAttrSequence?.p === 'type=Sequence',
      staticAttrSet: PageState.staticFilterType != 'tsp' && PageState.staticAttrSetId && Results.filterAttrs.find(_ => _.attr_id === PageState.staticAttrSetId)
    })
    // PageState.staticAttrSetId = ''
    Vue.set(Results, 'filterAttrs', filterAttrs)
  },
  dealingKidsTag ({ Results }, { kidsTag }) { 
    Vue.set(Results, 'kidsTag', kidsTag)
  },
  dealingFilterTsps({ Results, PageState }, { filterTsps }) {
    if (!Array.isArray(filterTsps) || !filterTsps.length) {
      Vue.set(Results, 'filterTsps', [])
      return
    }

    const hasStaticTsp = PageState.staticAttrSetId && PageState.staticFilterType == 'tsp'
    const tsps = filterTsps.map(({ tagGroupId, tagGroupName, tagGroupValue, tagList = [], nodeType }) => {
      // 每一项tag加上tagGroupId

      // 处理 staticFilterType
      if (hasStaticTsp && tagGroupId === PageState.staticAttrSetId) { 
        const oldIndex = Results.filterTsps.findIndex(_ => _.tagGroupId === PageState.staticAttrSetId)
        if (oldIndex > -1) return Results.filterTsps[oldIndex]
      }

      const list = tagList.map(({ tagId, tagName, nodeType }) => ({ tagName, tagId: `${tagGroupId}_${tagId}`, nodeType }))
      return { tagGroupId, tagGroupName: tagGroupValue || tagGroupName, tagList: list, nodeType }
    })
    Vue.set(Results, 'filterTsps', tsps)
    PageState.staticAttrSetId = ''
  },
  dealingCloudTags({ Locals: { lang }, Language, Results }, { listAbtResult: { AllListQuickShip, listquickshipKey, listlabelQuickship }, cloudTags, cat_info }) {
    const { mallCode } = cat_info
    // 列表指定mall的场景（入口跳转到列表带mallCode），过滤掉mall标签筛选，目的不让用户进行任何mall筛选
    cloudTags = cloudTags?.filter(_ => +_.type === 1 ? !mallCode : _.tag_id)
    if (!cloudTags?.length) return Vue.set(Results, 'cloudTags', [])
    const filterTags = []
    cloudTags.forEach(_ => {
      if (_.is_sale_tag && _.promotionInfo?.typeId) {
        // 促销标签展示逻辑 样式内置 TODO
        _.tag_name = transformSaleTags({ promotionInfo: _.promotionInfo, lang, language: Language })
        _.icon = 'sui_icon_activity_24px'
        _.promotion_tag = 1
      }
      // 新版标签云组件补参
      _.value = _.tag_id || ''
      _.label = _.tag_name || ''

      if (+_.type === 1) {
        const { language_mall_name, mall_tags } = Results?.mallInfo?.find(mall => mall.mall_code == _.tag_id) || {}
        const isOverSeaMall = mall_tags?.includes('4')
        const isLocalMall = mall_tags?.includes('5')
        // 只展示，仅是本地mall或跨境mall的标签
        const isLocalMallOnly = isLocalMall && !isOverSeaMall
        const isOverSeaMallOnly = !isLocalMall && isOverSeaMall
        if ((isLocalMallOnly || isOverSeaMallOnly) && language_mall_name) {
          _.label = language_mall_name
          _.mall_tag = 1
          _.local_mall = 0
          if (isLocalMallOnly) {
            _.icon = 'sui_icon_shipping_12px'
            _.local_mall = 1
          }
          filterTags.push(_)
        }
      } else if (+_.type === 2) {
        if (_.tag_id == 'quickship') {
          _.isNewQs = listlabelQuickship?.p?.listlabelQuickship === 'newQuickship'
          const showQuickShip = AllListQuickShip?.p === 'type=B'
          
          const listquickKey = listquickshipKey?.p?.listquickKey === 'newKey'
          const quickShipText = listquickKey ? Language.SHEIN_KEY_PWA_30900 : Language.SHEIN_KEY_PWA_22276

          if (!showQuickShip) return
          _.icon = 'sui_icon_shipping_12px'
          _.label = quickShipText
          _.quickship_tag = 1
          filterTags.push(_)
        }
      } else if (_.is_black_friday_tag) {
        _.icon = 'sui_icon_hot_12px'
        filterTags.push(_)
      } else {
        filterTags.push(_)
      }
    })

    Vue.set(Results, 'cloudTags', filterTags)
  },
  dealingBannerTag({ Results }, { bannerTag = {} }) {
    const handleBannerTag = (data = {}) => {
      // 更改筛选项
      if (Results?.bannerTag?.billno) {
        // 当前筛选项不存在一键购标签
        if (!bannerTag?.showTag) {
          Vue.set(Results, 'bannerTag', {
            ...Results.bannerTag,
            showTag: 0,
          })
        } else {
          Vue.set(Results, 'bannerTag', {
            ...Results.bannerTag,
            showTag: 1,
          })
        }
        return
      }
      if (!data) return false

      Vue.set(Results, 'bannerTag', data)
    }

    handleBannerTag(bannerTag)
  },
  dealingTrackings({ Results }, { cat_info, fhContext, googleContext, tracking, request_ext, goodsCrowId }) {
    Vue.set(Results, 'tracking', tracking)
    Vue.set(Results, 'request_ext', request_ext)
    Vue.set(Results, 'goodsCrowId', goodsCrowId)
    // nextpage不更新筛选需要维持第三方埋点原先值
    if (cat_info.requestType !== 'nextpage') {
      Vue.set(Results, 'fhContext', fhContext) // init接口
      Vue.set(Results, 'googleContext', googleContext) // init接口
    }
  },
  /**
   * Start - initState -- 将 vuex 数据装载至组件
   * @param {*} payload getters + payload部分数据
   */
  initPage({ PageState }, { catInfo, searchTraceId }) {
    PageState.ready = true
    PageState.hasFilt = [
      'child_cat_id',
      'attr_ids',
      'brand_ids',
      'tag_ids',
      'min_price',
      'max_price',
      'pic_nav_id',
      'tsp_ids'
    ].some(key => catInfo[key] || catInfo[key] === 0)
    PageState.searchTraceId = searchTraceId
  },
  initSearchCCCCard({ ComponentState }, { searchCCCCard, catInfo }) {
    if (catInfo.requestType !== 'firstload' || !searchCCCCard) return
    ComponentState.searchCCCCard = searchCCCCard
    
  },
  initBrandBanner({ ComponentState }, { locals: { IS_RW, IMG_LINK }, catInfo, currentCat, language, appendGoods }) {
    if (catInfo.requestType !== 'firstload') return
    if (catInfo.type === 'selection' && appendGoods?.[0]?.goods_img && currentCat.brande_detail) {
      let logoImg = currentCat.brande_detail.brand_logo
      let description = decodeURI(encodeURI(currentCat.brande_detail.brand_introduction).replace(/%C2%A0/g, '%20'))
      if (IS_RW && currentCat.brand_code == 28 && currentCat.brande_detail.detail_type == 2) {
        logoImg = IMG_LINK.romwex
        description = language.SHEIN_KEY_PWA_19574
      }
      ComponentState.BrandBanner = logoImg && description
        ? {
          bannerImg: appendGoods[0].goods_img,
          logoImg,
          description
        }
        : null
    }
  },
  initBgBanner({ ComponentState }, { cccConfig }) {
    const { bgBanner } = cccConfig

    ComponentState.BgBanner = bgBanner && bgBanner.src ? bgBanner : null
  },
  initListTopInfo({ ComponentState }, { appendGoods, catInfo, searchKeywords, goods }) {
    const hideListTopInfo = catInfo.type === 'search' && getSearchDiff(catInfo).hideListTopInfo
    if (hideListTopInfo) return 
    const nextStatus = {
      show: !!goods.length || !!appendGoods.length,
      showCoupon: catInfo.type === 'selection' && catInfo.ShowTip === '1',
      showSilLabel: catInfo.type === 'search' && /^s1`ListSearch/.test(catInfo.ici) && catInfo.sil_type, // sil c1 c2 非 entry 暂时只能这样判定
      showPostCorrection: searchKeywords?.is_suggest_res,
    }
    if (catInfo.requestType === 'firstload') {
      ComponentState.ListTopInfo = nextStatus
    } else {
      ComponentState.ListTopInfo.show = nextStatus.show
      ComponentState.ListTopInfo.showSilLabel = nextStatus.showSilLabel
    }
  },
  initQueryParams ({ ComponentState }, { catInfo }) { 
    ComponentState.queryParams = getFilterBarQueryParams(catInfo)
  },
  updateQueryParams ({ ComponentState }, params) { 
    ComponentState.queryParams = {
      ...ComponentState.queryParams,
      ...params
    }
  },
  initFilterBar({ Results, ComponentState, Locals: { currency } }, { listAbtResult, cccOperation: { promotion_label }, catInfo, cloudTags, dailyDates, filterCates, filterAttrs, filterPrices, filterTsps, picTopNavCate, picTopNav, cccConfig, cccxFilterConfig, picksMenuList, shouldUseCccxFilterChain, showStoreDefaultWords, kidsTag, isTopTrendResultPage }) {
    if (catInfo.requestType === 'nextpage') return

    const { ListTopNavigation, ListTopNavigationJson, recSwitch, SearchTopNavigation, ListLeftFilter, SearchLeftFilter, showTspFilter, MostPopular, ListFilter } = listAbtResult
    let is_navtype_tagdata
    const recSwitch124 = recSwitch?.p?.recSwitch?.split('|')?.includes('124')
    const abtParam = ((recSwitch124 ? ListTopNavigation : ListTopNavigationJson) || SearchTopNavigation)?.p
    if (typeof abtParam === 'string') {
      // 说明是搜索图文导航的 abt, 它还是一个字符串, 还未迁移到新 abt 平台
      is_navtype_tagdata = abtParam.includes('nav_version')
    } else if (abtParam && typeof abtParam === 'object') {
      is_navtype_tagdata = Boolean(abtParam.nav_version)
    } else {
      is_navtype_tagdata = false
    }

    const filterLeftkey = SearchLeftFilter || ListLeftFilter || {}
    const exportConfig = getAttrExporConfigByAbt({ ListFilter, MostPopular, pageName: catInfo?.pageName })

    try {
      ComponentState.FilterBar = {
        filterData: JSON.parse(JSON.stringify({
          kidsTag,
          dailyDates,
          filterCates,
          filterAttrs,
          filterPrices,
          cloudTags,
          filterTsps,
          picTopNavCate,
        })),
        filterConfig: {
          currency,
          is_navtype_tagdata,
          topSucking: true,
          isCccxFilterConfig: shouldUseCccxFilterChain,
          filterLeftShow: filterLeftkey.p === 'type=A', // 左侧筛选
          isTopTrendResultPage, // v.趋势搜索结果页屏蔽该功能
          promotion_label,
          tagCloudSlotConfig: cccConfig?.tagCloudSlotConfig || [], // 标签插坑
          cccxFilterConfig: cccxFilterConfig?.filterRule || [], // cccxFilter
          productTypeSortConfig: cccConfig.productTypeSortConfig || [], // productType 外露标签云配置
          exposureTsp: exportConfig.isExportFilter ? true : showTspFilter?.p?.showTspFilter === 'show', // 外露tsp在筛选坑位
          hidePicTopNav: getSearchDiff(catInfo).hidePicTopNav, // 隐藏顶部图文导航
          hasPicNavTop: getSearchDiff(catInfo).hasPicNavTop, // 标签云
          ...exportConfig,
          ...getStickyConfig({ catInfo, picTopNav, picksMenuList, listAbtResult, bannerTag: Results.bannerTag, showStoreDefaultWords, isTopTrendResultPage }),
        }
      }
    } catch (error) {
      console.error('mutations/initFilterBar', error)
    }
  },
  initNoResult({ ComponentState, PageState: { hasFilt } }, { appendGoods, listAbtResult: { NoResultPage }, catInfo, goods }) {
    if (goods.length || appendGoods.length) return
    ComponentState.NoResult = {
      description: false,
      reselect: false,
      searchInput: false,
      searchTips: false,
      searchInAll: false,
      searchFeedback: false,
      searchRelated: false,
      noResultTipStyle: false,
    }
    if (hasFilt) {
      Object.assign(ComponentState.NoResult, {
        description: true,
        reselect: true,
      })
    } else if (catInfo.type === 'search') {
      if (/^s1`ListSearch/.test(catInfo.ici) && catInfo.sil_type) {
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          searchInAll: true,
          searchFeedback: true,
          searchRelated: true
        })
      } else if (getSearchDiff(catInfo).isStoreSearch) {
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          searchInAll: false,
          searchFeedback: false,
          searchRelated: false
        })
      } else {
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          searchInput: true,
          searchFeedback: true,
          searchRelated: true
        })
        // 搜索框吸顶
        Object.assign(ComponentState.NoResult, {
          icon: true,
          searchInput: false,
          searchFeedback_Mid: true,
          searchFeedback: false,
        })
        // 搜索没结果新样式
        if (NoResultPage?.p?.NoResultPage === 'new') {
          Object.assign(ComponentState.NoResult, {
            searchTips: true,
            description: false,
            reselect: false,
            searchInput: false,
            searchInAll: false,
            searchFeedback_Mid: false,
            searchRelated: true,
            searchFeedback: false,
            noResultTipStyle: true
          })
        }
        // 搜索没结果新样式 (品牌结果页)
        if (getSearchDiff(catInfo).isBrandSearch) {
          Object.assign(ComponentState.NoResult, {
            // description: true,
            reselect: false,
            searchInput: false,
            // searchTips: false,
            searchInAll: false,
            searchFeedback: false,
            searchRelated: false,
            noResultTipStyle: false,
          })
        }
      }
    } else {
      Object.assign(ComponentState.NoResult, {
        description: true,
      })
    }
  },
  // 底部信息 : 控制推荐显隐 + 底部描述文案
  initListBottomInfo({ ComponentState }, { language, cccOperation: { mobile_seo_text }, catInfo }) {
    if (catInfo.requestType !== 'firstload' || ['store'].includes(catInfo.search_type)) return
    ComponentState.ListBottomInfo = {
      seoText: mobile_seo_text,
      bttLang: language.SHEIN_KEY_PWA_15051,
    }
  },
  // 搜索反馈入口
  initSearchFeedback({ ComponentState }, { language, cccOperation: { mobile_feedback_entry }, catInfo }) {
    if (!mobile_feedback_entry) return
    if (catInfo.requestType === 'firstload') {
      ComponentState.SearchFeedback = {
        showTips: false,
        lock: false,
        hrefTarget: mobile_feedback_entry.hrefTarget,
        text: mobile_feedback_entry.text,
        sendLang: language.SHEIN_KEY_PWA_16977,
        thankLang: language.SHEIN_KEY_PWA_15692
      }
    } else if (+catInfo.page === 3 && catInfo.type === 'search' && ComponentState.SearchFeedback) {
      ComponentState.SearchFeedback.showTips = true
    }
  },

  initFilterAbt({ ComponentState }, { filterAbtParam }) {
    ComponentState.filterAbtParam = filterAbtParam
  },

  SETCATECOUPON({ ComponentState }, {
    result,
    pos
  }) {
    if (typeof pos === 'undefined') {
      ComponentState.ProductList.cateCoupon = result
    } else {
      ComponentState.ProductList.cateCoupon.Data[pos] = result
    }
  },
  SETCOUPONLANGUAGE({ ComponentState }, languageData) {
    ComponentState.ProductList.couponLanguage = languageData
  },

  // 改变页面的状态
  setPageState({ PageState }, result) {
    Object.assign(PageState, result)
  },

  // 改变banner标签状态
  setBannerTagState({ Results }, result = {}) {
    const bannerTag = {
      ...Results.bannerTag,
      ...result,
    }
    Vue.set(Results, 'bannerTag', bannerTag)
  },
  // 更新一键购新人引导/成人弹框的状态
  updateDialogShowStatus(state, { dialogName = '', result = false }) {
    state[dialogName] = result
  },
  // 设置购物车加车数量
  setGoodsCheckoutNum({ Results }, { checkoutedInfo = {}, showCheckout = false, showCheckoutNum = false }) {
    const originalGoods = Results.goods || []
    const checkoutedGoodsKeys = Object.keys(checkoutedInfo)

    originalGoods.forEach((item, index) => {
      const { goods_sn } = item
      if (checkoutedGoodsKeys.includes(goods_sn)) {
        const checkoutNum = checkoutedInfo[goods_sn].quantity

        if (Results.goods?.[index] && checkoutNum && showCheckoutNum) {
          Vue.set(Results.goods[index], 'checkoutNum', checkoutNum)
        }

        if (Results.goods?.[index] && showCheckout) {
          Vue.set(Results.goods[index], 'showCheckout', showCheckout)
        }
      }
    })
  },
  /**
   * @description: 首屏加载完成后处理数据
   * 性能优化：减少首屏加载时间
   */
  processDataAfterInitialLoad({ Results, ComponentState }, payload) {
    if (!payload) return
    let { filterCates } = payload
    filterCates = JSON.parse(JSON.stringify(filterCates?.length ? filterCates : []))
    Vue.set(Results, 'filterCates', filterCates)
    ComponentState.FilterBar.filterData.filterCates = filterCates
  },
  // 点刷需求存储已曝光商品id
  updateExposedGoods({ Results }, { goodsId, divideTime }) {

    const exposedGoods = [ ...Results.exposedGoods, divideTime ? { goods_id: goodsId, divideTime } : { goods_id: goodsId } ]

    Vue.set(Results, 'exposedGoods', exposedGoods)
  },
  // 清空点后刷相关数据
  resetExposeGoods({ Results }) {
    Vue.set(Results, 'exposedGoods', [])
    Vue.set(Results, 'exposedGoodsWithSpu', [])
    Vue.set(Results, 'exposeDynamicRecommendGood', [])
  },
  // 点刷需求存储已曝光商品id+spu
  updateExposedGoodsWithSpu({ Results }, { goodsId, spuId, divideTime }) {
    if (divideTime === 'recommend') {
      // 记录 页面中已经曝光的点推商品
      const exposedGoods = [...Results.exposeDynamicRecommendGood, `${goodsId}`]
      Vue.set(Results, 'exposeDynamicRecommendGood', exposedGoods)
      return
    }

    const exposedGoods = [...Results.exposedGoodsWithSpu, `${goodsId}-${spuId}`]

    Vue.set(Results, 'exposedGoodsWithSpu', exposedGoods)
  },
  // 处理点刷逻辑
}

export default mutations
